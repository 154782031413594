import React from 'react';
import Plot from 'react-plotly.js';
import { getDataSourceSrv } from '@grafana/runtime';

const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const OPS_KEYS = {
  "GLDREQ": new Set([
  "GLDREQ-19033",
  "GLDREQ-19023",
  "GLDREQ-19022",
  "GLDREQ-18985",
  "GLDREQ-18984",
  "GLDREQ-18983",
  "GLDREQ-18982",
  "GLDREQ-18981",
  "GLDREQ-18979",
  "GLDREQ-18978",
  "GLDREQ-18976",
  "GLDREQ-18971",
  "GLDREQ-18970",
  "GLDREQ-18968",
  "GLDREQ-18966",
  "GLDREQ-18965",
  "GLDREQ-18963",
  "GLDREQ-18962",
  "GLDREQ-18961",
  "GLDREQ-18960",
  "GLDREQ-18958",
  "GLDREQ-18957",
  "GLDREQ-18955",
  "GLDREQ-18954",
  "GLDREQ-18953",
  "GLDREQ-18952",
  "GLDREQ-18951",
  "GLDREQ-18950",
  "GLDREQ-18949",
  "GLDREQ-18948",
  "GLDREQ-18947",
  "GLDREQ-18946",
  "GLDREQ-18945",
  "GLDREQ-18944",
  "GLDREQ-18942",
  "GLDREQ-18941",
  "GLDREQ-18940",
  "GLDREQ-18939",
  "GLDREQ-18938",
  "GLDREQ-18937",
  "GLDREQ-18936",
  "GLDREQ-18934",
  "GLDREQ-18933",
  "GLDREQ-18932",
  "GLDREQ-18930",
  "GLDREQ-18928",
  "GLDREQ-18926",
  "GLDREQ-18924",
  "GLDREQ-18923",
  "GLDREQ-18922",
  "GLDREQ-18921",
  "GLDREQ-18920",
  "GLDREQ-18918",
  "GLDREQ-18913",
  "GLDREQ-18912",
  "GLDREQ-18911",
  "GLDREQ-18910",
  "GLDREQ-18909",
  "GLDREQ-18908",
  "GLDREQ-18907",
  "GLDREQ-18894",
  "GLDREQ-18888",
  "GLDREQ-18878",
  "GLDREQ-18877",
  "GLDREQ-18876",
  "GLDREQ-18871",
  "GLDREQ-18855",
  "GLDREQ-18851",
  "GLDREQ-18846",
  "GLDREQ-18840",
  "GLDREQ-18839",
  "GLDREQ-18837",
  "GLDREQ-18822",
  "GLDREQ-18814",
  "GLDREQ-18811",
  "GLDREQ-18806",
  "GLDREQ-18802",
  "GLDREQ-18798",
  "GLDREQ-18794",
  "GLDREQ-18792",
  "GLDREQ-18789",
  "GLDREQ-18785"
]), "ESCREQ": new Set([ 
  "ESCREQ-2631",
  "ESCREQ-2630",
  "ESCREQ-2629",
  "ESCREQ-2628",
  "ESCREQ-2627",
  "ESCREQ-2626",
  "ESCREQ-2625",
  "ESCREQ-1384",
  "ESCREQ-1383",
  "ESCREQ-1381",
  "ESCREQ-1347",
  "ESCREQ-1338",
  "ESCREQ-1337",
  "ESCREQ-1334",
  "ESCREQ-1321",
  "ESCREQ-1320",
  "ESCREQ-1319",
  "ESCREQ-1318",
  "ESCREQ-1316",
  "ESCREQ-1315",
  "ESCREQ-1312",
  "ESCREQ-1311",
  "ESCREQ-1307",
  "ESCREQ-1306",
  "ESCREQ-1304",
  "ESCREQ-1302",
  "ESCREQ-1301",
  "ESCREQ-1297",
  "ESCREQ-1294",
  "ESCREQ-1293",
  "ESCREQ-1291",
  "ESCREQ-1289",
  "ESCREQ-1287",
  "ESCREQ-1286",
  "ESCREQ-1280",
  "ESCREQ-1272",
  "ESCREQ-1266",
  "ESCREQ-1259",
  "ESCREQ-1245",
  "ESCREQ-1241",
  "ESCREQ-1232",
  "ESCREQ-1227",
  "ESCREQ-1220",
  "ESCREQ-1218",
  "ESCREQ-1211",
  "ESCREQ-1202",
  "ESCREQ-1201",
  "ESCREQ-1200",
  "ESCREQ-1194",
  "ESCREQ-1193",
  "ESCREQ-1191",
  "ESCREQ-1189",
  "ESCREQ-1187",
  "ESCREQ-1186",
  "ESCREQ-1178",
  "ESCREQ-1177",
  "ESCREQ-1176",
  "ESCREQ-1175",
  "ESCREQ-1174",
  "ESCREQ-1173",
  "ESCREQ-1166",
  "ESCREQ-1157",
  "ESCREQ-1156",
  "ESCREQ-1154",
  "ESCREQ-1153",
  "ESCREQ-1152",
  "ESCREQ-1150",
  "ESCREQ-1145",
  "ESCREQ-1139",
  "ESCREQ-1137",
  "ESCREQ-1136",
  "ESCREQ-1135",
  "ESCREQ-1133",
  "ESCREQ-1131",
  "ESCREQ-1126",
  "ESCREQ-1121",
  "ESCREQ-1120",
  "ESCREQ-1118",
  "ESCREQ-1115",
  "ESCREQ-1114",
  "ESCREQ-1112",
  "ESCREQ-1111",
  "ESCREQ-1108",
  "ESCREQ-1106",
  "ESCREQ-1105",
  "ESCREQ-1104",
  "ESCREQ-1103",
  "ESCREQ-1102",
  "ESCREQ-1099",
  "ESCREQ-1098",
  "ESCREQ-1096",
  "ESCREQ-1093",
  "ESCREQ-1085",
  "ESCREQ-1082",
  "ESCREQ-1080",
  "ESCREQ-1078",
  "ESCREQ-1075",
  "ESCREQ-1074",
  "ESCREQ-1073",
  "ESCREQ-1071",
  "ESCREQ-1069",
  "ESCREQ-1049",
  "ESCREQ-1048",
  "ESCREQ-1047",
  "ESCREQ-1043",
  "ESCREQ-1042",
  "ESCREQ-1039",
  "ESCREQ-1035",
  "ESCREQ-1034",
  "ESCREQ-1030",
  "ESCREQ-1028",
  "ESCREQ-1025",
  "ESCREQ-1023",
  "ESCREQ-1015",
  "ESCREQ-1013",
  "ESCREQ-1010",
  "ESCREQ-1005",
  "ESCREQ-1004",
  "ESCREQ-1001",
  "ESCREQ-999",
  "ESCREQ-995",
  "ESCREQ-991"
])
};

class Chart extends React.Component {
  constructor(props) {
    super(props);

    this.milestones = {
    "GLDREQ": [
      //https://colorkit.co/palette/00202e-003f5c-2c4875-8a508f-bc5090-ff6361-ff8531-ffa600-ffd380/
      {name: "Mission Operations Review", label: "MOR", month: "Oct 2023", date: new Date("October 19, 2023"), color: "003f5c"/* "eabfcb" */},
      {name: "Systems Integration Review", label: "SIR", month: "Jan 2024", date: new Date("January 1, 2023"), color: "2c4875" /* "c191a1" */ },
      {name: "Pre-Environmental Review", label: "PER", month: "Feb 2024", date: new Date("February 1, 2024"), color: "8a508f" /* "a4508b" */},
      {name: "PSR", label: "PSR", month: "Oct 2024", date: new Date("October 24, 2024"), color: "bc5090" /* "a4508b" */},
      {name: "[Mission] Operations Readiness Review", label: "ORR", month: "Dec 2024", date: new Date("December 4, 2024"), color: "ff6361" /* "5f0a87" */},
      {name: "Launch Readiness Review", label: "LRR", month: "Jan 2025", date: new Date("January 31, 2025"), color: "ff8531" /* "2f004f" */}
    ], "ESCREQ": [
      {name: "Mission Operations Review", label: "MOR", month: "Sep 2023", date: new Date("September 1, 2023"), color: "003f5c"/* "eabfcb" */},
      {name: "Pre-Environmental Review", label: "PER", month: "Dec 2023", date: new Date("December 1, 2023"), color: "8a508f" /* "a4508b" */},
      {name: "MOST", label: "MOST", month: "Jul 2024", date: new Date("July 22, 2024"), color: "2c4875" /* "c191a1" */ },
      {name: "Operational Readiness Test 1", label: "ORT1", month: "Aug 2024", date: new Date("August 5, 2024"), color: "bc5090" /* "a4508b" */},
      {name: "Operations Readiness Review", label: "ORR", month: "Aug 2024", date: new Date("August 20, 2024"), color: "ff6361" /* "5f0a87" */},
      {name: "Operational Readiness Test 2", label: "ORT2", month: "Aug 2024", date: new Date("August 26, 2024"), color: "#c4629b" /* "a4508b" */},
      {name: "Operational Readiness Test 3", label: "ORT3", month: "Sep 2024", date: new Date("September 16, 2024"), color: "#cc74a5" /* "a4508b" */},
      {name: "Flight Readiness Review", label: "FRR", month: "Sep 2024", date: new Date("September 17, 2024"), color: "ff8531" /* "2f004f" */}
    ]};

    this.state = {
      title: 'Verification Events',
      vesByStatus: {
        "Assigned": 60,
        "Verified": 11,
        "Closed":11
      },
      totalVEs: 82
    }
  }
  
  render() {

    //let milestones = generateMilestonData(this.milestones);
    let [id, closure, due, pkey] = this.props.data?.series[0]?.fields;
    pkey = pkey.values[0];
    let months = {};
let total=0;
    let firstMonth = due.values.concat(closure.values).filter(d=>!!d).reduce((first, d) => {
      return first<d?first:d;
    }, Number.MAX_SAFE_INTEGER);    
    let lastMonth = due.values.concat(closure.values).filter(d=>!!d).reduce((last, d) => {
      return last>d?last:d;
    }, Number.MIN_SAFE_INTEGER);
    let month = new Date(firstMonth);

    months[`${MONTH_NAMES[month.getUTCMonth()]} ${month.getUTCFullYear()}`] = {planned: 0, actual: 0};
    while (month <= lastMonth) {
      month.setUTCMonth(month.getUTCMonth() + 1);
      let label = `${MONTH_NAMES[month.getUTCMonth()]} ${month.getUTCFullYear()}`;
      
      months[label] = {planned: 0, actual: 0};
    }
console.log(months)
    let monthLabels = Object.keys(months).sort((a,b)=>(new Date(a) - new Date(b)));
    let totalVEs = OPS_KEYS[pkey].size;//id.values.length;
    let milestones = [];//pkey==="GLDREQ"? mname.values.reduce((milestones, mn, i) => {milestones.push([mn, mdate[i]]); return milestones}, []) : [];
    let currentMonth = `${MONTH_NAMES[new Date().getUTCMonth()]} ${new Date().getUTCFullYear()}`;
    let currentMonth_i = monthLabels.findIndex(label => label === currentMonth);

    id.values.forEach((id, i) => {
      //only keep operations issues
      if(!OPS_KEYS[pkey].has(id)) { return }

      let
        dueDate = new Date(due.values[i]),
        dueLabel = `${MONTH_NAMES[dueDate.getUTCMonth()]} ${dueDate.getUTCFullYear()}`,
        closureDate = new Date(closure.values[i]),
        closureLabel = `${MONTH_NAMES[closureDate.getUTCMonth()]} ${closureDate.getUTCFullYear()}`;
      total++;
      months[dueLabel].planned++;
      closure.values[i] && (months[closureLabel].actual++);
    });
console.log(total);
    return (
      <Plot
        data={([
          {
            x: monthLabels,
            y: monthLabels.map(ml => months[ml]).reduce((accum, m) => {
              let
                prev = accum.length === 0? totalVEs : accum[accum.length - 1],
                current = prev - (m.planned || 0);
              accum.push(current);
              return accum;
            }, []),
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: 'green'},
            name: "Planned",
          },
          {
            x: monthLabels,
            y: monthLabels.map(ml => months[ml]).reduce((accum, month, month_i) => {
              let
                prev = accum.length === 0? totalVEs : accum[accum.length - 1],
                current = (month_i <= currentMonth_i)? prev - (month.actual ||  0) : null;
              accum.push(current);
              return accum;
            }, []),
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: 'darkblue'},
            name:  'Actual'
          },
          /*{
            x: this.months,
            y: this.months.map(m => this.state.vesByDueDate[m]?.planned || 0),
            text: this.months.map(m => this.state.vesByDueDate[m]?.planned || 0),
            //yaxis: "y2",
            type: 'bar',
            marker: {color: 'blue', opacity: .5},
            name: 'Planned VEs this Month'
          }*/
        ]).concat(generateMilestonData(this.milestones[pkey], totalVEs))}
        
        layout={ {
          width: this.props.width,
          height: this.props.height,
          /* yaxis: {
            tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90]
          }, */
          title: this.state.title,//'Carruthers MOS Verification Events',
          xaxis: {
            type: "category"
          },
          yaxis: {
            title: "Verification Events",
            autoMargin: true
          }
        } }
      />
    );
  }
}

function generateMilestonData(milestones, max) {
  return milestones.reduce((data, milestone) => {
    data.push({
      x: [milestone.month, milestone.month],
      y: [0, max],
       type: 'scatter',
      marker: {color: milestone.color, opacity:0},
      line: {dash: "dashdot", width:1},
      name: milestone.label
    });

    return data;
  }, [])
}

export default Chart;
